"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.push = push;
exports.replace = replace;
exports.go = go;
exports.goBack = goBack;
exports.goForward = goForward;
exports.default = void 0;

var _history = _interopRequireDefault(require("@tmp/history"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

/* global window */
function push() {
  _history.default.push.apply(_history.default, arguments);
}

function replace() {
  _history.default.replace.apply(_history.default, arguments);
}

function go() {
  _history.default.go.apply(_history.default, arguments);
}

function goBack() {
  _history.default.goBack.apply(_history.default, arguments);
}

function goForward() {
  _history.default.goForward.apply(_history.default, arguments);
}

var _default = {
  push: push,
  replace: replace,
  go: go,
  goBack: goBack,
  goForward: goForward
};
exports.default = _default;